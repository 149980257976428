/* Provide sufficient contrast against white background */
a {
  color: #030d18;
  text-decoration: none !important;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.form-con {
  background-image: url("./Assets/img/formBg.jpg");
  height: 100vh;
  width: 100%;
}

/* #Alert {
  width: 300px;
  padding: 20px;
  font-size: 80%;
  background: #ffffffdd;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  font-weight: bold;
  color: white;
} */

/* > svg {
  flex-shrink: 0;
}

* + * {
  margin-left: 10px;
} */

.success {
  background: #569d58;
  color: white;
  margin-left: 10px;
  width: 300px;
  padding: 20px;
  border-radius: 20px;
}

.error {
  background: #d9350f;
  color: white;
  margin-left: 10px;
  width: 300px;
  padding: 20px;
  border-radius: 20px;
}
